$mobile-section-margin: 40px;
$lang-connect-height: 60px;

%column {
    min-width: 150px;
    margin-left: 20px;

    @include respond-below(lg) {
        margin: 0;
    }
}

%section-title {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;

    @include respond-below(lg) {
        margin-bottom: 12px;
    }
}

%connect-or-language-container {
    margin-top: $mobile-section-margin;

    @include respond-above(tablet) {
        margin: 0 0 0 50px;
    }
}

%connect-or-language-title {
    @include respond-above(tablet) {
        display: none;
    }
}
