@import '/app/src/shared/theme/mixins.scss'; @import '/app/src/shared/theme/var.scss'; @import '/app/src/theme/var.scss';
.rootContainer {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    min-height: 100vh;

    .container {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
    }
}
